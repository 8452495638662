@import "constants";
@import "font";
@import "colors";
@import "ui-libraries-overwrites";

* {
    font-family: $eon-brix-fontstack !important;

    &:focus {
        outline: none;
    }
}

body {
    color: $eon-darkgrey-75;
    font-size: 14px;
}

button svg {
    pointer-events: none;
}

button.mat-accent {
    background: $eon-turquoise-75 !important;
    color: $eon-white;
}

h2 {
    color: $eon-darkgrey-active;
    font-size: 40px;
    font-weight: 900;
}

button {
    text-transform: uppercase;
}

.cbms-caption-wrapper {
    width: 100%;
    display: flex;
}

.cbms-margin-auto {
    margin: auto;
}

.cbms-margin-left-auto {
    margin-left: auto !important;
}

.cbms-margin-left {
    margin-left: $default-margin !important;
}

.cbms-margin-right {
    margin-right: $default-margin !important;
}

.cbms-margin-right-05-rem {
    margin-right: 0.5rem !important;
}

.cbms-small-margin-left {
    margin-left: $small-margin !important;
}

.cbms-big-margin-left {
    margin-left: $big-margin;
}

.cbms-progressbar {
    opacity: 0;
    flex: none;
}

.cbms-progressbar-visible {
    opacity: 1;
    flex: none;
}

.cbms-button {
    color: #000 !important;
    height: 36px;
    font-size: 12px;
    text-transform: uppercase !important;

    .mat-icon {
        font-size: 1.125rem;
        height: 1.125rem !important;
        width: 1.125rem !important;
    }

    &__turquoise-bg {
        background-color: $eon-turquoise !important;
        color: white !important;
        font-size: 12px !important;
        font-weight: 700 !important;

        &:hover {
            background-color: $eon-turquoise-dark !important;
        }
    }

    &__red-bg {
        background-color: $eon-red !important;
        color: #fff !important;
        font-size: 12px !important;
        font-weight: 700 !important;
    }

    &__white-bg {
        background-color: $eon-white !important;
        color: #000 !important;
    }

    &__full-width {
        width: 100%;
    }

    &__is-active {
        background-color: $eon-turquoise !important;
        color: #fff !important;
    }

    // &:hover {
    //     background-color: $eon-turquoise !important;
    //     color: #fff !important;
    // }
}

.icon-button {
    // &:hover {
    //     background-color: $eon-turquoise !important;
    //     color: #fff !important;
    // }
}

.cbms-button:disabled {
    background-color: #0000001f !important;
    color: #00000042 !important;
}


.cbms-spinner-wrapper {
    display: flex;
    width: 100%;
    height: 80px;
    align-items: center;
    justify-content: center;
}

.cbms-spinner {
    margin: 5px auto 0 auto;
    stroke: $eon-red;
}

%base-cbms-title-wrapper {
    display: flex;
    flex-direction: row;
    margin-left: $app-margin;
    margin-right: $app-margin;
    margin-bottom: 10px;
    padding-top: 15px;
    font-size: 16px;
}

.cbms-title-wrapper-baseline {
    @extend %base-cbms-title-wrapper;
    align-items: baseline;
}

.cbms-title-wrapper-flex-end {
    @extend %base-cbms-title-wrapper;
    align-items: flex-end;
}


.cbms-title-wrapper-center {
    @extend %base-cbms-title-wrapper;
    align-items: center;
}

.cbms-title-wrapper {
    @extend %base-cbms-title-wrapper;
}

.cbms-red-color {
    color: $eon-red;
}

.cbms-redBg {
    background-color: rgb(244 77 77) !important;
    color: #FFF;
    padding: 5px 10px;
}

.cbms-yellowBg {
    background-color: rgb(243 240 76) !important;
    color: gray;
    padding: 5px 10px;
}

.cbms-grey-color {
    color: $eon-middlegrey;
}

.cbms-green-color {
    color: $eon-green;
}

.cbms-yellow-color {
    color: $eon-limeyellow;
}

.cbms-overflow__hidden {
    overflow: hidden !important;
}

.cbms-overflow__visible {
    overflow: visible !important;
}

.mat-icon__small {
    height: 18px;
}

.mat_icon__big {
    width: 60px !important;
    height: 60px !important;
}

.cbms-sidebar-header {
    padding: 20px 0 10px 0;
    border-bottom: 1px solid $eon-middlegrey;
    margin-bottom: 20px;

    h2 {
        margin: 0 0 20px 0;
        padding: 0;
        font-size: 30px;
        font-weight: 600;
    }

    h4 {
        margin: 0;
    }
}

.cbms-input__mid-size,
.cbms-dropdown__mid-size {
    width: $input-mid-size !important;
    margin-right: $small-margin !important;
}

.cbms-input__small-size, .cbms-input__small-size .p-inputnumber-input {
    width: 50px !important;
}

.cbms-chips {
    display: inline-block;
    border-radius: 3px;
    font-size: 14px;
    padding: 0.2145em 0.429em;
    margin: 0.286em 0.286em 0 0;
    background: $eon-middlegrey !important;
    color: #ffffff;
}

.cbms-chips-with-remove-button {
    .cbms-chips {
        position: relative;
        padding-right: 30px;

        i {
            position: absolute;
            right: 5px;
            cursor: pointer;
        }
    }
}

button.cbms-row-button {
    padding: 0.429em;
    min-width: 2.357em;
    line-height: 1;

    &.center_aligned {
        display: block;
        margin: 0 auto;
    }

    .mat-icon {
        margin-right: 0;
        width: 22px;
        height: 22px;
    }
}

.cbms-api-response-feedback {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    &__message {
        display: flex;
        align-items: center;
        font-weight: bold;
        white-space: normal;
    }

    &__code {
        font-size: 80%;
        padding-left: 24px;
    }

    &__icon {
        margin: 0px 5px 0 0;
        line-height: 1;
    }

    .mat-icon {
        height: 14px;
        width: 18px;

        &.bigger {
            height: 20px;
            width: 20px;
        }
    }
}

.cbms-text-align-center {
    text-align: center;
}

.cbms-text-align-left {
    text-align: left;
}

.cbms-text-align-right {
    text-align: right;
}

.cbms-align-center {
    align-items: center;
}

.cbms-opacity-06 {
    opacity: 0.6;
}

.cbms-form-input-error {
    p {
        color: #a80000;
    }

    input {
        border-color: #a80000 !important;
    }
}

.cbms-display-flex-row {
    display: flex;
    flex-direction: row;
}

.cbms-inline-flex {
    display: inline-flex;
    align-items: center;
}

.cbms-flex-align-item-center {
    align-items: center;
}

.cbms-disply-flex-row-centered {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    text-align: left;
}

.cbms-disply-flex {
    display: flex;
}

.cbms-justify-content-flex-start {
    justify-content: flex-start;
}

.cbms-justify-content-center {
    justify-content: center;
}

.cbms-justify-content-space-around {
    justify-content: space-around;
}

.cbms-justify-content-space-between {
    justify-content: space-between;
}

.cbms-font-weight-bold {
    font-weight: bold;
}

a .pi {
    font-family: "primeicons" !important;
    color: #848484;
}

.margin_top_bottom_10px {
    margin: 10px 0;
}

.cbms-margin-top10 {
    margin-top: 10px;
}

.cbms-margin-bottom05 {
    margin-bottom: 0.5em !important;
}

.cbms-margin-bottom20 {
    margin-bottom: 20px;
}

.cbms-width-120-px {
    width: 120px;
}

.cbms-width-3-percent {
    width: 3%;
}

.cbms-title-icon {
    margin-right: 16px;
    width: 32px !important;
    height: 32px !important;
}

.cbms-title-text {
    color: $eon-darkgrey;
    font-size: 40px;
    font-weight: 900;
}

.cbms-no-devices-wrapper {
    display: flex;
    font-size: 24px;
    font-weight: 600;

    align-items: center;
    justify-content: center;

    width: 100%;
    height: 80px;
}

.cbms-column-center {
    display: flex;
    justify-content: center;
}

.cbms-flex-left {
    display: flex;
    justify-content: flex-start;
}

.cbms-vertical-align {
    display: flex;
    align-items: center;
}

.cbms-button-green-hover:hover {
    background-color: #f4f4f4 !important;
    color: #333333 !important;
}

.cbms-min_height_36 {
    min-height: 36px;
}

.cbms-generic-table-style {
    .cbms-chips {
        font-size: inherit;
    }

    .pi-sort:before {
        content: "\e99e";
    }

    .pi-sort-down:before {
        content: "\e9a0";
    }

    .pi-sort-up:before {
        content: "\e9a2";
    }

    button.mat-flat-button {
        border: 1px solid $eon-darkgrey-25;
    }

    button.mat-flat-button:hover {
        border: 1px solid #fff;
    }

    button.mat-button-disabled {
        box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2),
            0px 0px 0px 0px rgba(0, 0, 0, 0.14),
            0px 0px 0px 0px rgba(0, 0, 0, 0.12);
    }

    button.mat-button-disabled:hover {
        background-color: rgba(0, 0, 0, 0.12);
        color: rgba(0, 0, 0, 0.26);
    }

    .p-datatable .p-datatable-thead>tr>th,
    .p-datatable .p-datatable-tbody>tr>td,
    .p-paginator {
        border: none;
    }

    .p-datatable .p-datatable-thead>tr>th {
        text-transform: uppercase;
        padding: 10px 0.857em;
        background-color: #e0e0e0;
        padding-left: 1.3em;
    }

    .p-table .p-table-caption,
    .p-table .p-table-summary,
    .p-datatable .p-datatable-header {
        background: none !important;
        border: none !important;
        padding: 0.571em 0 !important;
    }

    .p-datatable .p-datatable-tbody>tr>td {
        padding-left: 1.3em;
    }

    .p-datatable-scrollable-header-table {
        background-color: #e0e0e0;
    }

    .p-datatable .p-sortable-column.p-highlight,
    .p-datatable .p-sortable-column:not(.p-highlight):hover {
        background-color: #f4f4f4 !important;
        color: #333333 !important;
    }

    .p-datatable .p-datatable-tbody>tr:nth-child(even) {
        background-color: #f4f4f4;
    }

    .button-column {
        width: 180px;
    }

    .hide-column {
        display: none;
    }
}

.p-treetable .p-treetable-tbody>tr>td {
    padding: 0.571em 0.857em !important;
}

.cbms-generic-tree-table-style {
    .p-treetable .p-treetable-tbody>tr>td .p-treetable-toggler:enabled:hover {
        color: #a6a6a6;
    }

    .p-treetable .p-treetable-thead>tr>th {
        text-transform: uppercase;
        padding: 10px 0.857em;
        background-color: #e0e0e0;
    }

    .p-treetable .p-treetable-tbody>tr>td,
    .p-treetable .p-treetable-thead>tr>th {
        border: none;
    }

    .p-treetable .p-treetable-tbody>tr:nth-child(even) {
        background-color: #f4f4f4;
    }
}

.cbms-nested-tables-style {
    .p-datatable .p-datatable-thead>tr>th {
        text-transform: uppercase;
        text-align: left;
        padding: 10px 0.857em;
        background-color: #e0e0e0;
    }

    .p-datatable .p-datatable-thead>tr>th,
    .p-datatable .p-datatable-tbody>tr>td {
        border: none;
    }
}

body .pi {
    font-family: "primeicons" !important;
}

body .p-dialog {

    .cbms-generic-table-style .p-datatable .p-table-caption,
    .cbms-generic-table-style .p-datatable .p-table-summary {
        padding-top: 0 !important;
    }

    .p-dialog-titlebar {
        background-color: #ffffff;
        padding-top: 25px;
        padding-bottom: 30px;
    }

    .p-dialog-content {
        border-top: 0;

        .content {
            padding: 15px 0 0 0;
            margin: 0 15px 15px;
            border-top: 1px solid #8f9192;
        }
    }

    .p-dialog-titlebar .p-dialog-title {
        font-size: 30px;
        font-weight: 600;
    }
}

body {
    .p-tree {
        border: none;
        padding: 0;
        width: 21em;

        .pi-caret-down:before {
            content: "\e930";
        }

        .pi-caret-right:before {
            content: "\e932";
        }
    }
}

body .p-tree .p-tree-container .p-treenode .p-treenode-content:focus {
    box-shadow: none;
}

.upload-button {
    background-color: #1ea2b1;
    color: white;
    height: 36px;
    font-size: 12px;
    font-weight: 700;
    border-radius: 1px;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
        0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    border-radius: 4px;

    &.white {
        .p-button {
            background-color: #ffffff !important;
            border: none !important;
            color: #000000 !important;

            &:hover {
                background-color: #1ea2b1 !important;
                color: #ffffff !important;
            }
        }
    }

    .p-button-icon {
        font-size: 13px;
        font-weight: 900;
    }

    .p-button.p-fileupload-choose {
        font-size: 12px;
        display: flex;
        justify-content: center;
        line-height: 36px;
        height: 36px;
        text-transform: uppercase;
        padding-top: 0;
        padding-bottom: 0;
    }

    .p-button-label {
        font-weight: 700;
    }

    .p-fileupload-choose.p-focus {
        box-shadow: none;
    }
}

.export-button {
    width: 2.357rem !important;
    padding: 0.429rem 0 !important;
    justify-content: center;

    i {
        font-size: 13px;
        font-weight: 900;
    }
}

.visibility-hidden {
    visibility: hidden;
}

.icon-button {
    margin-left: 5px;

    .mat-icon {
        vertical-align: sub !important;
        width: 16px !important;
        height: 16px !important;
    }
}

button:hover {
    background-color: $eon-turquoise;
    color: #fff;
}

.manage-value {
    .p-button.p-button-icon-only {
        height: 1rem;
    }
}

.cbms-blink {
    animation: blinker 1s linear infinite;
    animation-iteration-count: 2;
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}

.no-bg-btn {
    background: none;
    border: none;
    cursor: pointer;

    &:hover {
        background: none !important;
        border: none !important;
        color: #000 !important;
    }

    i {
        position: relative;
        display: flex;
        font-size: 2.25rem;
    }

    span {
        position: absolute;
        top: -12px;
        left: 8px;
        font-size: 1.225rem;
        font-weight: bold;
        color: $eon-red;
    }
}

.cbms-margin-left0 {
    margin-left: 0 !important;
}

.cbms-content-wrapper {
    padding: 0 30px;

    &.gray-bg {
        background: #c8c8c8;
    }
}

.cbms-alert-switch.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
    background: red !important;
}

body .p-overlaypanel.cbms-mid-size {
    max-width: 515px !important;
}

.cbms-overlay-confirmation-buttons {
    justify-content: flex-end;
    display: flex;

    button {
        margin-right: 7px;
    }
}

.cbms-checkbox-list {
    margin-bottom: 15px;

    // ul {
    //     height: 215px;
    //     overflow-y: scroll;
    // }
}

body .p-button.cbms-site-filter-button {
    background: none !important;
    border: 1px solid #a6a6a6 !important;
    color: #000 !important;
    font-weight: 700;
    font-size: 16px;
    text-transform: capitalize;
}

.cbms-label-small-text {

    .p-checkbox-label,
    .p-radiobutton-label {
        font-size: 12px !important;
    }
}

.cbms-full-width {
    width: 100%;
}

.cbms-text-size18 {
    font-size: 18px;
}

.cbms-text-size25 {
    font-size: 25px;
}

.cbms-white-space-normal {
    white-space: normal;
}

.cbms-menu-container {
    list-style: none;
    margin: 0;
    padding: 0;
}

.cbms-menu-item {
    display: flex;
    height: 51px;
    padding: 0 10px 0 20px;
    border-bottom: 1px solid #e8e8e8;
    cursor: pointer;

    &:hover {
        background-color: #f6f6f7;
        padding-left: 15px;
        border-left: 5px solid #f48d84;
    }

    &.active-menu-item {
        background-color: #f6f6f7;
        padding-left: 15px;
        font-weight: 600;
        border-left: 5px solid #ea1c0a;
    }

    a {
        line-height: 51px;
        text-align: left;
        font-size: 16px;
        color: #39393a;
        text-transform: uppercase;
        letter-spacing: .03rem;
        flex: auto;
        text-decoration: none;
        align-self: center;
        outline: none;
        display: block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}

.cbms-button-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
}

.delete-action-buttons {
    display: flex;
}

.p-calendar.cbms-correct-calendar-position {
    .p-inputtext {
        width: 158px;
    }

    .p-datepicker {
        left: -162px !important;
    }
}

.noPadding {
    .timeframe-filter {
        padding: 0 !important;
    }
}

//flex
.flex {
    display: flex !important;
}

.inline-flex {
    display: inline-flex !important;
}

.align-items-stretch {
    align-items: stretch !important;
}

.align-items-start {
    align-items: flex-start !important;
}

.align-items-center {
    align-items: center !important;
}

.align-items-end {
    align-items: flex-end !important;
}

.align-items-baseline {
    align-items: baseline !important;
}

.justify-content-start {
    justify-content: flex-start !important;
}

.justify-content-end {
    justify-content: flex-end !important;
}

.justify-content-center {
    justify-content: center !important;
}

.justify-content-between {
    justify-content: space-between !important;
}

.justify-content-around {
    justify-content: space-around !important;
}

.justify-content-evenly {
    justify-content: space-evenly !important;
}

.cbms-gray-text {
    color: #c8c8c8;
}

.cbms-h2 {
    margin: 30px 0 20px 0;
    padding: 0;
    font-size: 30px;
    font-weight: 600;
}

p.error {
    color: #a80000;
}

.error {
    .p-dropdown {
        border-color: #a80000 !important;
    }
}

@media (max-width: '1410px') {
    .app-body {
        overflow-y: hidden;
    }

    .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .sites-wrapper {
        height: calc(100vh - 203px) !important;
    }
}

.required-field::after {
    content: '*';
    color: red;
}
