@use "../styles/colors.scss";

button {
    .mat-icon {
        margin-right: 5px;
    }
}

// Spinner
.mat-mdc-progress-spinner .mdc-circular-progress__determinate-circle, 
.mat-mdc-progress-spinner .mdc-circular-progress__indeterminate-circle-graphic {
    stroke: $eon-red !important;
}

// Tab Bar
.mat-tab-link {
    min-width: 150px !important;
}

.mat-tab-nav-bar.mat-primary .mat-ink-bar {
    background-color: $eon-red !important;
}

.mat-tab-link-container {
    z-index: 3 !important;
    overflow: unset !important;

    a {
        color: $eon-darkgrey-75 !important;
    }

    a:hover {
        color: $eon-middlegrey !important;
    }
}

.mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, 
.mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
    border-color: $eon-red !important;
}

.mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, 
.mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
    color: $eon-darkgrey-75 !important;
}

.mat-mdc-tab .mdc-tab__text-label, .mat-mdc-tab-link .mdc-tab__text-label {
    color: $eon-darkgrey-75 !important;
    opacity: 0.6;
}

.mdc-tab, .mdc-button {
    -webkit-font-smoothing: initial !important;
}

.mdc-button {
    text-transform: uppercase;
    letter-spacing: normal;
}

.cbms-generic-table-style .mat-mdc-unelevated-button:not(:disabled) {
    border: 1px solid #bfbfbf;
    height: 33px;
    font-size: 12px;
    line-height: 28px;

    &:hover {
        border: 1px solid #fff;
        background-color: #1ea2b1;
        color: #fff;
    }
}

.mat-tab-header {
    overflow: unset !important;
}

.mat-mdc-tab-header, .mat-mdc-tab-link-container {
    overflow: visible !important;
}

.mat-mdc-tab-link-container {
    z-index: 2 !important;
}

// expansion panel

.mat-expansion-panel-header-title {
    align-items: center;
    flex-grow: 0 !important;
    flex-basis: auto !important;
    margin-right: 5px !important;
}

.mat-expansion-panel-content {
    //background-color: $eon-darkerlightgrey !important;
    background-color: #FFFFFF !important;
}

.gateway-tab {
    .mat-expansion-panel-content {
        background-color: $eon-darkerlightgrey !important;
    }
}

.mat-expansion-panel-body {
    padding: 0 !important;
}

// progress bar

.mat-progress-bar-fill::after {
    background-color: $eon-red !important;
}

// multiselect

.p-multiselect-panel
    .p-multiselect-items
    .p-multiselect-item.p-highlight,
    .p-listbox-item.p-highlight {
    background-color: $eon-red !important;
    color: $eon-white !important;
    outline: none !important;
}

body .p-checkbox-box.p-highlight {
    background-color: white !important;
    color: black !important;
}

.p-dropdown .p-dropdown-label.p-placeholder {
    color: #333;
}

.p-checkbox .p-checkbox-box .p-checkbox-icon {
    color: black !important;
}

body
    .p-multiselect-panel
    .p-multiselect-header
    .p-multiselect-filter-container
    .p-multiselect-filter-icon {
    color: $eon-red !important;
}

body .p-multiselect .p-multiselect-panel {
    min-width: 200px;
}
body .p-multiselect .p-multiselect-label.p-placeholder {
    color: black !important;
}

body .min-width500 .p-multiselect .p-multiselect-panel {
    min-width: 500px;
}

body .min-width300 .p-dropdown .p-dropdown-panel {
    min-width: 300px;
}

body .dd-min-width200 .p-dropdown {
    min-width: 200px
}

body .dd-width-20-percent .p-dropdown {
    width: 20%;
}

.p-sidebar .p-sidebar-header + .p-sidebar-content {
    padding-bottom: 150px;
}

body .min-width250 .p-dropdown {
    min-width: 250px;
}

body .p-component {
    font-family: $eon-brix-fontstack !important;
    font-size: 14px;
}

body .p-inputtext {
    border-color: #a6a6a6 !important;
    font-size: 0.875rem;
}

body .p-inputgroup {
    margin-bottom: $default-margin;
    width: 50%;
    align-items: center;
    display: block;
}

// table

// .p-datatable-scrollable .p-datatable-thead>tr, 
// .p-datatable-scrollable .p-datatable-tbody>tr, 
// .p-datatable-scrollable .p-datatable-tfoot>tr {
//     display: flex;
//     flex-wrap: nowrap;
//     width: 100%;
// }

body .p-datatable .p-datatable-tbody > tr.p-highlight,
body .p-datatable .p-datatable-tbody > tr:nth-child(even).p-highlight {
    background-color: $eon-red;
}

body .p-datatable .p-datatable-tbody > tr:focus > td:last-child,
body .p-datatable .p-datatable-tbody > tr:focus > td {
    box-shadow: none !important;
}

body .p-datatable .p-sortable-column.p-highlight {
    background-color: #e0e0e0 !important;
    color: #333333 !important;
}

body .p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
    color: #333333 !important;
}

body .p-datatable .p-datatable-tbody > tr > td {
    font-size: 0.9em;

    &.p-editing-cell {
        padding: 0.571em 0.857em !important;
    }
}

// spinner textbox

body .p-spinner {
    width: 100%;
}

body .p-spinner-input {
    border: 1px solid #c8c8c8 !important;
}

body .p-button {
    background-color: $eon-turquoise;
    border: 1px solid $eon-turquoise;

    &.selected {
        background-color: $eon-white;
        border: 1px solid $eon-white;
        color: black;

        &:hover {
            color: black;
        }
    }

    &:enabled:hover {
        border-color: $eon-turquoise-dark;
        background-color: $eon-turquoise-dark;
    }
}

.p-fileupload-choose:not(.p-disabled):hover {
    background-color: $eon-turquoise-dark;
    border-color: $eon-turquoise-dark;
}

 body .p-button.p-button-text {
    color: #000;
    background: transparent;
    border-color: transparent;

    &:enabled:hover {
        color: #000;
        border-color: transparent;
        background: rgba(0,122,217,.04);
    }
 }

// dialog

body .p-dialog-title {
    font-size: 16px;
}

// auto complete token
body
    .p-autocomplete.p-autocomplete-multiple
    .p-autocomplete-multiple-container
    .p-autocomplete-token {
    background: $eon-middlegrey !important;
    margin: 0.286em 0.286em 0 0 !important;
}
body
    .p-autocomplete.p-autocomplete-multiple
    .p-autocomplete-multiple-container {
    padding-bottom: 0.5em !important;
}

.style-confirmation-message {
    &.p-widget {
        background: #FFF;
    }

    .p-dialog-content.p-widget-content {
        display: flex;
        align-items: baseline;
        margin-left: 20px;
        margin-right: 20px;
        border: none;
        border-top: 1px solid $eon-middlegrey;
    }
}

// pagination overwrite active button color
.p-paginator .p-paginator-pages .p-paginator-page {
    font-size: 0.875rem;

    &.p-highlight {
        background-color: $eon-red;
        color: $eon-white !important;
    }
}

//change radio button color to red
body .p-radiobutton .p-radiobutton-box.p-highlight,
body .p-radiobutton .p-radiobutton-box.p-focus,
body .p-radiobutton .p-radiobutton-checked:not(.p-disabled):hover {
    border-color: $eon-red !important;
    background-color: $eon-red !important;
}

.p-fileupload-choose input[type=file] {
    z-index: 0;
}

.p-datatable .p-sortable-column .p-sortable-column-badge {
    display: none !important;
}

.p-multiselect-footer {
    padding: .25em;
}

.p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container {
    width: 70%;
}

.filter-item .p-multiselect-header {
    flex-wrap: wrap;

    .filter-header {
        flex: 0 0 100%;
    }
}

.p-sidebar {
    p {
        line-height: 1.5;
        margin: 0;
    }
}

.cbms-sidebar-menu {
    h2 {
        line-height: 51px;
        font-size: 16px;
        color: #39393a;
        text-transform: uppercase;
        letter-spacing: 0.03rem;
        padding-left: 20px;
    }

    &.p-sidebar-left {
        // top:50px;
        .p-sidebar-content {
            padding: 0;
        }
    }
}

.p-overlaypanel {
    .p-overlaypanel-content {
        min-width: 500px;

        p {
            line-height: 1.5;
            margin: 0;
        }
    }

    .p-overlaypanel-close, .p-overlaypanel-close:enabled:hover {
        background-color: $eon-turquoise;
    }
} 

.p-link:focus {
    box-shadow: none !important;
}

.p-tree-toggler-icon .pi, .p-tree-toggler .pi {
    font-size: 10px !important;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler:enabled:hover {
    color: #a6a6a6;
}

.p-dialog {
    .p-dialog-header {
        background-color: #fff;
        padding-top: 25px;
        padding-bottom: 30px;
        border-bottom: none;

        .p-dialog-title {
            font-size: 30px;
            font-weight: 600;
        }
    }
}

.p-datepicker table {
    font-size: 0.875rem;
    td {
        padding: 0.2rem;

        > span {
            width: 2.2rem;
            height: 2.2rem;
        }
    }
}

//full calendar
.fc .fc-view-container th {
    padding: .571em 1em;
}

.fc .fc-view-container td.fc-widget-content {
    background: none;
}

.fc-time-grid .fc-slats .fc-minor td {
    border-top-style: dotted;
}

.p-radiobutton-label.small,
.p-checkbox-label.small {
    font-size: 12px !important;
}

.p-datatable.p-datatable-scrollable.p-datatable-flex-scrollable {
    width: auto !important;
}
.p-datatable-flex-scrollable>.p-datatable-wrapper {
    z-index: 0;
}

.fc .fc-timegrid-slot {
    height: 0.8rem !important;
}

//fix for dropdown red border on init
.ng-untouched.ng-invalid.ng-dirty {
    .p-dropdown {
      border-color: #a6a6a6 !important;
    }
}

// tabMenu
.p-tabmenu .p-tabmenu-nav {
    background: #ffffff;
    border: 1px solid #dee2e6;
    border-width: 0 0 2px 0;
  }
  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem {
    margin-right: 0;
  }
  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
    font-size: 0.875rem;
    border: solid #dee2e6;
    border-width: 0 0 2px 0;
    border-color: transparent transparent #fff transparent;
    background: #ffffff;
    color: #6c757d;
    padding: 0.875rem 1.5rem;
    font-weight: 700;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    transition: box-shadow 0.2s;
    margin: 0 0 -2px 0;
  }
  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link .p-menuitem-icon {
    margin-right: 0.5rem;
  }
  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link:not(.p-disabled):focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none
  }
  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem:not(.p-highlight):not(.p-disabled):hover .p-menuitem-link {
    background: #ffffff;
    border-color: #adb5bd;
    color: #6c757d;
    opacity: 0.4;
  }
  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
    background: #ffffff;
    border-color: red;
    color: red;
  }
  .p-tabmenu .p-tabmenu-left-icon {
    margin-right: 0.5rem;
  }
  .p-tabmenu .p-tabmenu-right-icon {
    margin-left: 0.5rem;
  }
  .p-tabmenu .p-tabmenu-nav-btn.p-link {
    background: #ffffff;
    color: #3B82F6;
    width: 3rem;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    border-radius: 0;
  }
  .p-tabmenu .p-tabmenu-nav-btn.p-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 0.2rem #BFDBFE;
  }

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
    font-weight: 300;
    color: #404040 !important;
    opacity: .6;
}

.cbms-generic-table-style {
    .p-column-filter-menu-button.p-column-filter-menu-button-active, 
    .p-column-filter-menu-button.p-column-filter-menu-button-active:hover {
        background: none;
        color: $eon-turquoise;
    }

    .p-column-filter-menu-button:hover {
        color: $eon-turquoise;
    }
}

// input switch
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
    background: $eon-turquoise;
}

.p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
    background: $eon-turquoise;
}

.highcharts-reset-zoom {
    display: none !important;
}

.p-overlaypanel.table-actions-overlay {
    max-width: 300px;
    margin: 0;
    
    &::before, &::after {
        display: none;
    }

    .p-overlaypanel-content {
        min-width: 100%;
        padding-bottom: 0.371rem;
    }

    button {
        margin-top: 5px;
        margin-bottom: 10px;
        width: 100%;
    }
}